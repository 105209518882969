module.exports = {
  methods: {
    isValid(coordinates) {
      return coordinates && coordinates.latitude && coordinates.longitude;
    },
    safeLatLngToProviderFormat(coordinates) {
      if (this.isValid(coordinates)) {
        return this.latLngToProviderFormat(coordinates);
      }
      return null;
    },
  },
  watch: {
    pickup: 'fitBounds',
    destination: 'fitBounds',
    driverPosition: 'fitBounds',
    waypoints: 'fitBounds',
    center: 'fitBounds',
  },

  computed: {
    pickupLatLng() {
      return this.safeLatLngToProviderFormat(this.pickup);
    },
    center() {
      if (this.isValid(this.pickup)) {
        return this.latLngToProviderFormat(this.pickup);
      }
      if (this.isValid(this.defaultCenterLatLng)) {
        return this.latLngToProviderFormat(this.defaultCenterLatLng);
      }
      return [0, 0];
    },
    destinationLatLng() {
      return this.safeLatLngToProviderFormat(this.destination);
    },
    driverPosition() {
      return this.safeLatLngToProviderFormat(this.driverLatLng);
    },
    waypointsLatLng() {
      if (this.waypoints) {
        const waypointsCoordinates = [];
        this.waypoints.forEach((coordinates) => {
          if (this.isValid(coordinates)) {
            waypointsCoordinates.push(this.latLngToProviderFormat(coordinates));
          }
        });

        return waypointsCoordinates;
      }
      return [];
    },
  },
};
